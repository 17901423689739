<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
    mixins: [mixinPage],
    components: {},
    name: "x2",
    data() {
        return {
            pageName: this.$options.name,
            xtysData: [
                {
                    icon: "icon1",
                    t1: "会员营销功能",
                    t2:
                        "全新会员营销系统除了提供传统的线下会员卡办理、充值、消费功能外，还支持线上无卡式会员功能，关注公众号即成为会员。另外还提供多种营销方式提高企业针对会员的营销能力，与微信、会员系统联动。同时满足企业对营运营销、会员营销双重要求。",
                    img: "img2",
                },
                {
                    icon: "icon2",
                    t1: "管理人员远程赠送",
                    t2:
                        "老板或管理人员可以通用小程序查询当前的营业状况，查询帐单的消费明细，并根据自己的权限进行营销赠送操作。",
                    img: "img7",
                },
                {
                    icon: "icon3",
                    t1: "管理人员小程序应用功能",
                    t2:
                        "管理人员、服务员可以将自己的帐号信息在小程序上绑定，绑定后便可以实现落单、查单、签送等功能，落单时可以支持扫客人的微信（支付宝）支付、扫客人的会员云码支付、提供支付码给客人扫码支付及其他付款方式支付等。",
                    img: "img5",
                },
                {
                    icon: "icon17",
                    t1: "POS机点单收款一体化",
                    t2:
                        "支持移动POS机点单收款一体化，同时支持移动化寄存酒、员工刷卡登陆算业绩、移动打印酒水单、移动赠送、清台、卡台状态查询等，支持即结、后结混合使用，支持微信、支付宝、花呗、银行卡等付款方式；支持移动收单查询。",
                    img: "img14",
                },
                {
                    icon: "icon4",
                    t1: "云报表查询",
                    t2:
                        "提供了公众号或小程序云报表查询两种方式，涵盖了老板及高管人员日常关心的所有报表及图表，让老板随时随地掌握运营数据。",
                    img: "img4",
                },
                {
                    icon: "icon5",
                    t1: "老板语音查询",
                    t2:
                        "系统在原有老板云报表的基础上创新开发了语音ＡＩ查询功能，老板只需说出你想查询的数据（例如营业收入、预订状况），小程序马上展现出结果。",
                    img: "img3",
                },
                {
                    type: 1,
                    icon: "icon6",
                    t1: "一体化互联网对接、打通线上线下业务",
                    t2:
                        "后台设置、报表查询、库存管理等模块已实现了向线上迁移，还有小程序（或公众号）实现一系列的订货、点单、查询、支付等功能。",
                    img: "img6",
                },
            ],
            gnmkData: [
                {
                    bg: "bg1",
                    icon: "icon1",
                    t1: "咨客管理",
                    t2:
                        "实现预定、领位、修改预订、短信通知、客人信息查询等功能。",
                },
                {
                    bg: "bg2",
                    icon: "icon2",
                    t1: "前台收银",
                    t2:
                        "实现预定、开台、点单、转台、经理签送、经理打折、会员打折、包房发送信息、挂帐还款、查看房台操作记录、打印帐单、买单结帐等。",
                },
                {
                    bg: "bg3",
                    icon: "icon3",
                    t1: "楼面点单",
                    t2:
                        "实现点单、退单、经理签送与打折、查看消费及打印小票等功能，还可以辅助实现存取酒功能。",
                },
                {
                    bg: "bg4",
                    icon: "icon4",
                    t1: "移动应用",
                    t2:
                        "移动应用包括了客人扫码点单与支付，服务人员POS机一体化点单、收款及凭据打印，管理人员及服务员的小程序应用、老板云报表查询等。",
                },
                {
                    bg: "bg5",
                    icon: "icon5",
                    t1: "出品监控",
                    t2:
                        "主要负责出品部门的酒水出品确认、监控、库存查询、沽清等操作，还负责存取酒操作及库存酒水的领用登记操作，对会员的存酒进行过期或延期管理。",
                },
                {
                    bg: "bg6",
                    icon: "icon6",
                    t1: "财务管理",
                    t2:
                        "查询会计收支平衡表、营业汇总表、酒水销量报表、订房业绩报表、点单提成报表、会员往来帐分析、重要操作记录报表。",
                },
                {
                    bg: "bg7",
                    icon: "icon7",
                    t1: "库存管理",
                    t2:
                        "实现申购单、入库单、出库单、退库单、调拨单、报损单、直拨单、退货单的管理，实现仓库或部门的盘点功能。",
                },
                {
                    bg: "bg8",
                    icon: "icon8",
                    t1: "超市收银",
                    t2:
                        "超市收银可以独立运行，也可以配合前台进行房台挂单、取单等操作。主要是实现超市商品快速买单、挂单、取单、挂房帐、经理签送、经理打折、会员打折、会员取酒等功能。",
                },
                {
                    bg: "bg9",
                    icon: "icon9",
                    t1: "后台管理",
                    t2:
                        "实现系统运营的相关设置，包括酒水类别、酒水菜牌、套餐、房台类型、楼层区域、房费收费标准、提成设置、人员权限、赠送权限、折扣方案及付款方式等。",
                },
            ],
            khList: [
                {
                    url: require("@/images/ylkh/1.jpg"),
                    t1: "广州阿曼尼夜城酒吧",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/2.jpg"),
                    t1: "惠东菲曼酒吧",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/3.jpg"),
                    t1: "惠东皇朝酒吧",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/4.jpg"),
                    t1: "金都大酒店",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/5.jpg"),
                    t1: "纳斯顿皇家会所",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/6.jpg"),
                    t1: "绅豪俱乐部",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/7.jpg"),
                    t1: "粤大金融城国际酒店",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/8.jpg"),
                    t1: "云浮传奇1933酒吧",
                    t2: "",
                },
                {
                    url: require("@/images/ylkh/9.jpg"),
                    t1: "珠海金山林酒店",
                    t2: "",
                },
            ],
        };
    },
    methods: {
        handleScroll() {
            let that = this;
            // 页面滚动距顶部距离
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            // console.log(scrollTop);
            this.scrollTop = scrollTop;
            that.xtysScroll();
        },
    },
    mounted() {},
    activated() {},
};
</script>



